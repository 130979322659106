<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="350">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="openBtn" color="#137547" dark v-bind="attrs" v-on="on"> Kategorije </v-btn>
      </template>
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Odabir kategorije</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container class="py-0">
          <v-row align="center" justify="start">
            <v-col v-for="(selection, i) in selections" :key="selection.text" class="shrink">
              <v-chip :disabled="loading" close @click:close="selected.splice(i, 1)">
                <v-icon left v-text="selection.icon"></v-icon>
                {{ selection.text }}
              </v-chip>
            </v-col>
            <v-col v-if="!allSelected" cols="12">
              <v-text-field ref="search" v-model="search" full-width hide-details label="Pretraga" single-line></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider v-if="!allSelected"></v-divider>
        <v-list>
          <template v-for="item in categories">
            <v-list-item v-if="!selected.includes(item)" :key="item.text" :disabled="loading" @click="selected.push(item)">
              <v-list-item-avatar>
                <v-icon :disabled="loading" v-text="item.icon"></v-icon>
              </v-list-item-avatar>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false"> Odbaci </v-btn>
          <v-btn color="green darken-1" text @click="dialog = false"> Primeni </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    items: [
      {
        text: "Mašine i oprema",
        icon: "mdi-tractor",
      },
      {
        text: "Prerada",
        icon: "mdi-cogs",
      },
      {
        text: "Biljna proizvodnja",
        icon: "mdi-sprout",
      },
      {
        text: "Stočarska proizvodnja",
        icon: "mdi-cow",
      },
      {
        text: "Test",
        icon: "mdi-test-tube",
      },
    ],
    loading: false,
    search: "",
    selected: [],
  }),

  computed: {
    allSelected() {
      return this.selected.length === this.items.length;
    },

    categories() {
      const search = this.search.toLowerCase();

      if (!search) return this.items;

      return this.items.filter((item) => {
        const text = item.text.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },

    selections() {
      const selections = [];

      for (const selection of this.selected) {
        selections.push(selection);
      }

      return selections;
    },
  },

  watch: {
    selected() {
      this.search = "";
    },
  },

  methods: {
    next() {
      this.loading = true;

      setTimeout(() => {
        this.search = "";
        this.selected = [];
        this.loading = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.openBtn {
  margin: 30px auto;
}
</style>
