<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent max-width="350">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="#137547" dark v-bind="attrs" v-on="on"> Filter </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5"> Odaberite opcije </v-card-title>
        <v-card-text>
          <div class="second">
            <h3>Cena:</h3>
            <v-col class="minMaxPrice" cols="3"><v-text-field label="Min"></v-text-field></v-col>
            <p>-</p>
            <v-col class="minMaxPrice" cols="3"><v-text-field label="Max"></v-text-field></v-col>
          </div>
          <DatePick />
          <div class="first">
            <CategoryDrop />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false"> Odbaci </v-btn>
          <v-btn color="green darken-1" text @click="dialog = false"> Primeni </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CategoryDrop from "./CategoryDrop.vue";
import DatePick from "./DatePick.vue";

export default {
  data() {
    return {
      dialog: false,
    };
  },

  components: {
    CategoryDrop,
    DatePick,
  },
};
</script>

<style lang="scss" scoped>
.first {
  position: left;
  display: inline-block;
}

.second {
  position: left;
  h3 {
    display: inline-block;
  }
  p {
    display: inline-block;
  }
  .minMaxPrice {
    margin-top: -15px;
    display: inline-block;
  }
}
</style>
